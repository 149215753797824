<template>
  <div class="app-container">
    <div class="mytabel table-sm mr-0 ml-0 p-0">
      <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
        <div
          class="col-7 p-0 align-self-center font-weight-bold d-flex align-items-center w-reset mb-sm-2"
        >
          <h5 class="d-inline mr-2 font-weight-bold">
            {{ $t("message.report_doctors") }}
          </h5>
          <crm-refresh @c-click="refresh()"></crm-refresh>
          <div class="text-center d-flex sorddata ml-3">
            <el-input
              size="mini"
              :placeholder="$t('message.search')"
              prefix-icon="el-icon-search"
              v-model="filterForm.search"
              clearable
            ></el-input>
            <el-date-picker
              size="mini"
              v-model="filterForm.start_date"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              :placeholder="$t('message.start_date')"
            ></el-date-picker>
            <el-date-picker
              size="mini"
              v-model="filterForm.end_date"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              :placeholder="$t('message.end_date')"
            ></el-date-picker>
          </div>
        </div>
        <div
          class="col-5 align-items-center align-self-center text-right pr-0 d-flex justify-content-end w-reset"
        >
          <!-- <el-button
            size="mini"
            @click="fetchRep()"
            icon="el-icon-document-delete"
          >
            Excel
          </el-button> -->
          <crm-column-settings
            :columns="columns"
            :modelName="'doctorsReport'"
            @c-change="updateColumn"
          ></crm-column-settings>
        </div>
      </div>
      <table class="table table-bordered table-hover" v-loading="loadingData">
        <crm-pagination
          :pagination="pagination"
          @c-change="updatePagination"
        ></crm-pagination>
        <thead>
          <tr>
            <crm-th :column="columns.id" :sort="sort" @c-change="updateSort">{{
              columns.id.title
            }}</crm-th>
            <crm-th
              :column="columns.surname"
              :sort="sort"
              @c-change="updateSort"
              >{{ columns.surname.title }}</crm-th
            >
            <crm-th
              :column="columns.name"
              :sort="sort"
              @c-change="updateSort"
              >{{ columns.name.title }}</crm-th
            >
            <crm-th
              :column="columns.patronymic"
              :sort="sort"
              @c-change="updateSort"
              >{{ columns.patronymic.title }}</crm-th
            >

            <crm-th
              :column="columns.bonus_amount"
              :sort="sort"
              @c-change="updateSort"
              >{{ columns.bonus_amount.title }}</crm-th
            >
            <crm-th
              :column="columns.phone"
              :sort="sort"
              @c-change="updateSort"
              >{{ columns.phone.title }}</crm-th
            >

            <crm-th
              :column="columns.gender"
              :sort="sort"
              @c-change="updateSort"
              >{{ columns.gender.title }}</crm-th
            >

            <crm-th
              :column="columns.settings"
              :sort="sort"
              @c-change="updateSort"
              >{{ columns.settings.title }}</crm-th
            >
          </tr>
          <tr>
            <th v-if="columns.id.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.id"
                class="id_input"
                :placeholder="columns.id.title"
              ></el-input>
            </th>
            <th v-if="columns.surname.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.surname"
                :placeholder="columns.surname.title"
              >
              </el-input>
            </th>
            <th v-if="columns.name.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.name"
                :placeholder="columns.name.title"
              >
              </el-input>
            </th>
            <th v-if="columns.patronymic.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.patronymic"
                :placeholder="columns.patronymic.title"
              >
              </el-input>
            </th>

            <th v-if="columns.bonus_amount.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.bonus_amount"
                :placeholder="columns.bonus_amount.title"
              >
              </el-input>
            </th>
            <th v-if="columns.phone.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.phone"
                :placeholder="columns.phone.title"
              >
              </el-input>
            </th>
            <th v-if="columns.gender.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.gender"
                :placeholder="columns.gender.title"
              >
              </el-input>
            </th>

            <th v-if="columns.settings.show"></th>
          </tr>
      </thead>
      
        <transition-group name="flip-list" tag="tbody">
          <tr
            v-for="(user, index) in list"
            :key="user.bonusable_id"
            class="cursor-pointer"
          >
            <td v-if="columns.id.show">{{ index + 1 }}</td>
            <td v-if="columns.surname.show">
              {{ user.bonusable ? user.bonusable.surname : "" }}
            </td>
            <td v-if="columns.name.show">
              {{ user.bonusable ? user.bonusable.name : "" }}
            </td>
            <td v-if="columns.patronymic.show">
              {{ user.bonusable ? user.bonusable.patronymic : "" }}
            </td>
            <td v-if="columns.bonus_amount.show">
              {{
                user.bonus_amount
                  ? user.bonus_amount - user.paid_amount
                  : 0 | formatMoney
              }}
            </td>
            <td v-if="columns.phone.show">
              {{ user.bonusable ? user.bonusable.phone : "" | formatPhoneNumber }}
            </td>
            <td v-if="columns.gender.show">
              {{ user.bonusable ? user.bonusable.gender : "" }}
            </td>
            <td v-if="columns.settings.show" class="settings-td">
              <el-button
                @click="showReport(user)"
                type="success"
                icon="el-icon-view"
                round
                >{{ $t("message.reports") }}</el-button
              >
            </td>
          </tr>
        </transition-group>
      </table>
    </div>
    <el-drawer
      class="popups_title"
      size="90%"
      :wrapperClosable="false"
      :visible.sync="drawer"
      @opened="drawerOpened('cabinet_doctor')"
      :with-header="false"
    >
      <CabintDoctor
        :selected="selectedModel"
        @c-close="drawer = false"
        ref="cabinet_doctor"
      >
      </CabintDoctor>
    </el-drawer>
  </div>
</template>
<script>
import CabintDoctor from "./components/cabnet-doctor";
import { mapGetters, mapActions, mapState } from "vuex";
import list from "@/utils/mixins/list";
import {formatPhoneNumber} from "@/filters";

export default {
  mixins: [list],
  name: "partnerDoctorList",
  data() {
    return {
      data_s: "",
      loadingData: false,
      excel_fields: {},
      drawer: false,
      tableID: false,
      tableUser: false,
      selectedModel: {},
    };
  },
  components: {
    CabintDoctor,
  },
  computed: {
    ...mapGetters({
      list: "doctorsReport/list",
      columns: "doctorsReport/columns",
      pagination: "doctorsReport/pagination",
      filter: "doctorsReport/filter",
      sort: "doctorsReport/sort",
      specialties: "specialties/list",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },

  async created() {},
  async mounted() {
    this.controlExcelData();
    if (this.specialties && this.specialties.length === 0)
      await this.loadSpecialties();
  },
  watch: {
    columns: {
      handler: function () {
        this.controlExcelData();
      },
      deep: true,
    },
  },
  methods: {
    formatPhoneNumber,
    ...mapActions({
      updateList: "doctorsReport/index",
      getRep: "doctorsReport/getRep",
      updatePagination: "doctorsReport/updatePagination",
      updateSort: "doctorsReport/updateSort",
      updateFilter: "doctorsReport/updateFilter",
      updateColumn: "doctorsReport/updateColumn",
      showModel: "doctorsReport/show",
      refreshData: "doctorsReport/refreshData",
      loadSpecialties: "specialties/index",
    }),
    fetchData() {
      this.loadingData = true;
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      this.updateList(query)
        .then((res) => {
          this.dotorsReport = res.dotorsReport;
          this.loadingData = false;
        })
        .catch((err) => {
          this.loadingData = false;
        });
    },
    async show(model) {
      await this.showModel(model.id)
        .then((res) => {
          this.drawerShow = true;
        })
        .catch((err) => {});
    },
    fetchRep() {
      const query = {
        start_date: this.filterForm.start_date,
        end_date: this.filterForm.end_date,
      };
      this.getRep(query)
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Отчет-врачи.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.$notify({
            title: "Ошибка",
            type: "error",
            offset: 130,
            message: err.error.message,
          });
        });
    },
    drawerOpened(ref) {
      this.$refs[ref].afterOpen();
    },
    showReport(model) {
      // this.$loadingCursor('wait');
      // this.showModel({id:model.id})
      // .then(res => {
      // this.$loadingCursor('default');
      this.drawer = true;
      this.selectedModel = model;

      // }).catch(err => {
      //     this.$notify({
      //   title: "Ошибка",
      //   type: "error",
      //   offset: 130,
      //   message: err.error.message,
      // });
      //     this.$loadingCursor('default');
      //     this.drawer = false;
      // });
    },
    handleCurrentChange(val) {
      this.updatePagination({ key: "page", value: val })
        .then((res) => {})
        .catch((err) => {});
    },
    controlExcelData() {
      this.excel_fields = {};
      for (let key in this.columns) {
        if (this.columns.hasOwnProperty(key)) {
          let column = this.columns[key];
          if (column.show && column.column !== "settings") {
            this.excel_fields[column.title] = column.column;
          }
        }
      }
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => {});
    },
    async edit(model) {
      this.$loadingCursor("wait");
      await this.editModel(model.id)
        .then(async (res) => {
          this.$loadingCursor("default");
          this.drawerUpdate = true;
        })
        .catch((err) => {
          this.$loadingCursor("default");
        });
    },
    destroyModel(model) {
      this.delete(model.id)
        .then((res) => {
          this.$notify({
            title: "Успешно",
            type: "success",
            offset: 130,
            message: res.message,
          });
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
